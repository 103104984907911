import gql from 'graphql-tag';
// import {filter} from 'graphql-anywhere';

// Query
export const GET_LEADS = gql`
    query GetLeads {
        leads {
            ...lead
        }
    }
    fragment lead on Lead {
        updated_at
        email
        name
        phone
        state {
            initials
        }
        average_consumption
    }
`;

export const GET_LEAD = gql`
    query lead($email: String!) {
        lead(email: $email) {
            ...lead
        }
    }
    fragment lead on Lead {
        updated_at
        email
        name
        phone
        state {
            initials
            name
        }
        cpf
        promo_code
        step
        concessionaria
        average_consumption

        proposals {
            ...proposal
        }
        proposal {
            ...proposal
        }
        titular
        classe
        id
        zipcode
        installation_number
        utm_source
        utm_campaign
        utm_medium
        utm_transaction
    }
    fragment proposal on LeadProposal {
        name
        postal_code
        address
        district
        city
        state {
            initials
        }
        cpf
        consumer_unit
        installation_number
        classe
        subclasse
        tarifa
        history {
            ...history
        }
        discount
        power_plant {
            power_plant_id
            name
            power_monthly
        }
        average
        desvio_padrao
        custo_disp
        creditos
        mensalidade
        economia_mensal
        economia_anual
        economia_contrato
        percentual_planta
    }
    fragment history on ProposalHistory {
        mes_ano
        consumo
        dias
    }
`;

// Mutations
export const CREATE_LEAD = gql`
    mutation createLead(
            $id: ID!
            $email: String!
            $name: String!
            $cpf: String
            $phone: String
            $average_consumption: String
            $promo_code: String
            $step: Int!
            $state: InputState
            $proposals: [InputLeadProposal]
            $proposal: InputLeadProposal
        ) {
        	createLead(
            lead: {
                id: $id,
                email: $email,
                name: $name,
                cpf: $cpf,
                phone: $phone,
                average_consumption: $average_consumption,
                promo_code: $promo_code
                step: $step
                state: $state
                proposals: $proposals
                proposal: $proposal
            }
        ) {
            id
        }
    }
`;

export const UPDATE_LEAD = gql`
    mutation updateLead(
            $id: ID!
            $email: String!
            $name: String!
            $cpf: String!
            $phone: String
            $average_consumption: String
            $promo_code: String
            $step: Int!
            $state: InputState
            $proposals: [InputLeadProposal]
            $proposal: InputLeadProposal
            $installation_number: String
        ) {
        	updateLead(
            lead: {
                id: $id,
                email: $email,
                name: $name,
                cpf: $cpf,
                phone: $phone,
                average_consumption: $average_consumption,
                promo_code: $promo_code
                step: $step
                state: $state
                proposals: $proposals
                proposal: $proposal
                installation_number: $installation_number
            }
        ) {
            id
        }
    }
`;

export const STORE_LEAD = gql`
    mutation storeLead(
            $id: ID!
            $email: String!
            $name: String!
            $cpf: String
            $phone: String
            $average_consumption: String
            $promo_code: String
            $step: Int!
            $state: InputState
            $classe: String
            $concessionaria: String
            $titular: String
            $termouso: String
            $zipcode: String
            $proposals: [InputLeadProposal]
            $proposal: InputLeadProposal
            $utm_source: String
            $utm_medium: String
            $utm_campaign: String
            $utm_transaction: String
            $ID_document: String
            $profession: String
            $marital_status: String
            $marital_type: String
            $gender: String
            $installation_number: String
        ) {
        	storeLead(
            lead: {
                id: $id,
                email: $email,
                name: $name,
                cpf: $cpf,
                phone: $phone,
                average_consumption: $average_consumption,
                promo_code: $promo_code
                step: $step
                state: $state
                classe: $classe
                concessionaria: $concessionaria
                titular: $titular
                termouso: $termouso
                zipcode: $zipcode
                proposals: $proposals
                proposal: $proposal
                utm_source: $utm_source,
                utm_medium: $utm_medium,
                utm_campaign: $utm_campaign,
                utm_transaction: $utm_transaction
                ID_document: $ID_document
                profession: $profession
                marital_status: $marital_status
                marital_type: $marital_type
                gender: $gender
                installation_number: $installation_number
            }
        ) {
            id
        }
    }
`;

export const ProposalHistoryMutableFragment = gql`
fragment ProposalHistoryMutable on ProposalHistory {
    mes_ano
    consumo
    dias
}
`